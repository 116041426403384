import React from 'react'
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import Img from "gatsby-image"
import { Helmet } from "react-helmet"
import p01VO from "../audio/p01VO.m4a"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

function playAudio() {
  const audioEl = document.getElementsByClassName("audio-element")[0]
  audioEl.play()
}

const Page01 = ({ data }) => {
  return (
  <div className="main-container">
    <Helmet>
        <title>El Gran Llano - 01</title>
        <link href="https://fonts.googleapis.com/css?family=Fira+Sans&display=swap" rel="stylesheet" />
    </Helmet>
    <Link to="/Page02">
      <div className="page-turner right-page-pos">
      <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
      </div>
    </Link>
    <div className="centered-container">
      <div className="_idContainer000">
        <div className="page-turner">
        </div>
        <div className="backContainer004">
          <Img fluid={data.p01_back.childImageSharp.fluid} />
        </div>
        <div className="_idContainer005">
          <div className="_idContainer003">
            <div className="_idContainer001" onClick={playAudio}>
              <Img fluid={data.p01_botonCuentamelo.childImageSharp.fluid} />
            </div>
          </div>
        <div className="_idContainer004">
          <Img fluid={data.imageOne.childImageSharp.fluid} />
          <div className="creditos">
            Historia: Ana Coronado | Ilustraciones: Paulina Coronado
          </div>
        </div>
        </div>
      <div className="_idContainer006">
      <Img fluid={data.p01_letreroPoste.childImageSharp.fluid} />
    </div>
      </div>
      <audio className="audio-element">
        <source src={p01VO}></source>
      </audio>

    </div>
  <Footer />
  </div>
  )
}
///
export default Page01

export const pageQuery = graphql`
query {
imageOne: file(relativePath: { eq: "p01_letrero.png" }) {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
p01_letreroPoste: file(relativePath: { eq: "p01_letreroPoste.png" }) {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
p01_botonCuentamelo: file(relativePath: { eq: "p01_botonCuentamelo.png" }) {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
p01_back: file(relativePath: { eq: "p01_back.jpg" }) {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
}
`;
